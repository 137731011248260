import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useData } from 'hooks';
import { Select, Checkbox } from 'components';
import { extendStyles, formatCurrency } from 'helpers';

import { prepareSelect } from './helpers';

import styles from './styles.scss';

extendStyles(styles);

const Event = ({ formData, setup, error, handleChange, handleSelect }) => {
  const { events } = useData();

  const getEventName = useCallback((id) => {
    if (!events || !events[id]) return null;
    return events[id].billingDescription;
  }, [events]);

  const getEventDuration = useCallback((id) => {
    if (!events || !events[id]) return null;
    return events[id].eventDuration ? `${events[id].eventDuration} min` : '';
  }, [events]);

  const getEventCleanUp = useCallback((id) => {
    if (!events || !events[id]) return null;
    return events[id].eventCleanUpTime ? `${events[id].eventCleanUpTime} min` : '';
  }, [events]);

  const eventPrice = useMemo(() => {
    if (!events || !formData.event || !events[formData.event]) return null;
    return formatCurrency(events[formData.event].purchasePrice);
  }, [events, formData.event]);

  return (
    <div className={ styles.card }>
      <div className={ styles.row }>
        <div className={ styles.get('label', 'fixed') }>Event</div>
        <Select
          name="event"
          error={ error }
          style={ { width: '100%', marginRight: 0, marginBottom: 0 } }
          options={ prepareSelect({ data: events, teamId: setup.teamId }) }
          selected={ getEventName(formData.event) }
          onChange={ handleSelect }
          placeholder="Select Event"
        />
      </div>
      <div className={ styles.row }>
        <div className={ styles.group }>
          <div className={ styles.label }>Duration</div>
          <div className={ styles.value }>{ getEventDuration(formData.event) }</div>
        </div>
        <div className={ styles.group }>
          <div className={ styles.label }>Clean up</div>
          <div className={ styles.value }>{ getEventCleanUp(formData.event) }</div>
        </div>
      </div>
      <div className={ styles.row }>
        <div className={ styles.label }>Rate</div>
        <div className={ styles.value }>{ `${eventPrice || '$PurchasePrice'}` }</div>
      </div>
      <div className={ styles.row }>
        <Checkbox
          id="doneByFsEmployee"
          name="doneByFsEmployee"
          label="DONE BY FS EMPLOYEE"
          style={ { marginBottom: 5 } }
          onChange={ handleChange }
          checked={ formData.doneByFsEmployee }
        />
      </div>
    </div>
  );
};

Event.propTypes = {
  error: PropTypes.bool.isRequired,
  setup: PropTypes.shape({ teamId: PropTypes.number }).isRequired,
  formData: PropTypes.shape({
    event: PropTypes.number,
    doneByFsEmployee: PropTypes.bool,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default Event;
