import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Snackbar from '@material-ui/core/Snackbar';

import { useData } from 'hooks';
import guestEventsHandler from 'store/effects/guestEvents/handlers';
import guestServicesHandler from 'store/effects/guestServices/handlers';
import { extendStyles, getOptionIdByValue } from 'helpers';
import { Tabs, Select, DateGroup, TimeGroup, InputGroup, Message } from 'components';

import { Service, Event, Client, AddOnService } from './components';

import styles from './styles.scss';

extendStyles(styles);

const APPOINTMENTS = {
  EVENT: 'event',
  SERVICE: 'service',
};

const CLIENTS = {
  TEAM: 'Team',
  GUEST: 'Guest',
  VISITOR: 'Visitor',
};

export const TABS = [
  { id: APPOINTMENTS.SERVICE, label: 'APPOINTMENT' },
  { id: APPOINTMENTS.EVENT, label: 'EVENT' },
];

const Modal = ({ setup, appointment, onSave, onCancel }) => {
  const formRef = useRef({});
  const [action, setAction] = useState({ error: false, done: false, validation: false, changeLog: false });
  const [formData, setFormData] = useState({
    date: null,
    time: null,
    room: null,
    guest: null,
    event: null,
    service: null,
    clientType: CLIENTS.GUEST,
    addOnService: null,
    doneByFsEmployee: false,
  });
  const [errors, setErrors] = useState({
    date: false,
    time: false,
    room: false,
    service: false,
  });
  const [activeTab, setActiveTab] = useState(APPOINTMENTS.SERVICE);
  const [changeLog, setChangeLog] = useState({});
  const [activeClientTab, setActiveClientTab] = useState(CLIENTS.GUEST);
  const { teams, services, events, guestEventsRequests, guestServicesRequests, teamSessions, options } = useData();

  useEffect(() => {
    if (!appointment) return;

    setActiveTab(appointment.appointmentType);
    setActiveClientTab(appointment.attendeeType);

    const data = {
      date: moment(appointment.dateTime),
      time: moment(appointment.dateTime),
      room: getOptionIdByValue({ data: options, field: 'serviceRooms', value: appointment.room }),
      guest: +appointment.attendeeIds[0],
      event: appointment.appointmentType === 'event' ? appointment.eventId : null,
      service: appointment.appointmentType === 'service' ? appointment.serviceId : null,
      clientType: appointment.attendeeType,
      addOnService: appointment.addOnService,
      doneByFsEmployee: appointment.doneByFsEmployee,
    };

    setFormData(data);
    formRef.current = data;
  }, [appointment, options]);

  const serviceProvider = useMemo(() => {
    if (!teams || !teams[setup.teamId]) return null;
    const { firstName, lastName } = teams[setup.teamId];
    return `${firstName} ${lastName}`;
  }, [setup, teams]);

  const logChange = useCallback((name, value) => {
    if (!appointment) return;

    if (formRef.current[name] !== value) {
      if (name === 'date') {
        const oldValue = moment(formRef.current[name]).format('YYYY-MM-DD');
        const newValue = moment(value).format('YYYY-MM-DD');
        setChangeLog((state) => ({
          ...state,
          [name]: { from: oldValue, to: newValue },
        }));
      } else if (name === 'time') {
        const oldValue = moment(formRef.current[name]).format('YYYY-MM-DD HH:mm:ss');
        const newValue = moment(value).format('YYYY-MM-DD HH:mm:ss');
        setChangeLog((state) => ({
          ...state,
          [name]: { from: oldValue, to: newValue },
        }));
      } else {
        setChangeLog((state) => ({
          ...state,
          [name]: { from: formRef.current[name], to: value },
        }));
      }
    } else {
      setChangeLog((state) => {
        // eslint-disable-next-line no-param-reassign
        delete state[name];
        return state;
      });
    }
  }, [appointment]);

  const onDateUpdate = useCallback((name, value) => {
    logChange(name, value);
    setFormData((fd) => ({ ...fd, [name]: value }));
    if (value) setErrors((s) => ({ ...s, [name]: false }));
  }, [logChange]);

  const handleChange = (e) => {
    e.persist();

    if (e.target.type === 'checkbox') {
      const fieldName = e.target.name;
      const newState = formData[fieldName] ? !formData[fieldName] : true;

      setFormData((fd) => ({
        ...fd,
        [fieldName]: newState,
      }));
    }
  };

  const handleSelect = useCallback((name, value) => {
    logChange(name, value);
    if (name === 'service') {
      if (activeTab === APPOINTMENTS.SERVICE) {
        setFormData((fd) => ({
          ...fd,
          event: null,
          [name]: value,
        }));
      } else {
        setFormData((fd) => ({
          ...fd,
          service: null,
          [name]: value,
        }));
      }
      if (value) setErrors((s) => ({ ...s, service: false }));
    } else if (name === 'room') {
      setFormData((fd) => ({
        ...fd,
        [name]: value,
      }));
      if (value) setErrors((s) => ({ ...s, room: false }));
    }
    setFormData((fd) => ({
      ...fd,
      [name]: value,
    }));
  }, [activeTab, logChange]);

  const isAddOn = useMemo(() => {
    if (!appointment) return false;
    return ((appointment.appointmentType === 'event' && (appointment.eventId === null || appointment.eventId === undefined))
      || (appointment.appointmentType === 'service' && (appointment.serviceId === null || appointment.serviceId === undefined)))
      && appointment.addOnService !== null;
  }, [appointment]);

  const onTabSelect = useCallback((tabId) => {
    if (isAddOn) return;
    if (tabId !== activeTab) {
      setFormData((fd) => ({ ...fd, doneByFsEmployee: false }));
      setActiveTab(tabId);
    }
  }, [activeTab, isAddOn]);

  const onClientTabSelect = useCallback((tabId) => {
    if (isAddOn) return;
    setActiveClientTab(tabId);
    handleSelect('clientType', tabId);
    if (tabId !== activeClientTab) {
      handleSelect('guest', null);
    }
  }, [activeClientTab, isAddOn, handleSelect]);

  const getAttendeeType = useCallback((clientType) => {
    if (!options || !options.attendiesTypes) return null;

    const attendiesType = (options.attendiesTypes || []).find((item) => item.option === clientType && !item.archived);

    return attendiesType ? +attendiesType.id : null;
  }, [options]);

  const getRoomDetails = useCallback((room) => {
    if (!options.roomBuildings || !options.roomFloors) return null;

    const building = options.roomBuildings.filter((b) => !b.archived).find((item) => item.id === room.building);
    const floor = options.roomFloors.filter((f) => !f.archived).find((item) => item.id === room.floor);
    return `${room.option} | ${building ? `${building.option},` : ''} ${floor ? floor.option : ''} `;
  }, [options.roomBuildings, options.roomFloors]);

  const getRoomsOptions = useMemo(() => (
    options.serviceRooms
      .filter((rm) => !rm.archived)
      .map((room) => ({ id: +room.id, value: getRoomDetails(room) }))
      .sort((a, b) => {
        const A = a.value;
        const B = b.value;
        if (A === B) return 0;
        if (A > B) return 1;
        return -1;
      })), [getRoomDetails, options.serviceRooms]);

  const getSelectedRoom = useMemo(() => {
    if (formData.room) {
      const roomObj = getRoomsOptions.find((rm) => +rm.id === formData.room);
      if (roomObj) return roomObj.value;
    }
    return '';
  }, [formData.room, getRoomsOptions]);

  const getServiceFields = useCallback((id) => {
    const service = services[id];

    return {
      guests: formData.clientType === CLIENTS.GUEST ? [formData.guest] : [],
      room: formData.room,
      status: null,
      purchasePrice: service.purchasePrice,
      salesPrice: service.salesPrice,
      salesTax: service.salesTax,
      duration: service.serviceDuration,
      cleanUp: service.serviceCleanUpTime,
      addOnService: formData.addOnService,
      purchasePriceAddOnService: formData.addOnService ? services[formData.addOnService].purchasePrice : null,
      salesPriceAddOnService: formData.addOnService ? services[formData.addOnService].salesPrice : null,
      salesTaxAddOnService: formData.addOnService ? services[formData.addOnService].salesTax : null,
      durationAddOnService: formData.addOnService ? services[formData.addOnService].serviceDuration : null,
      cleanUpAddOnService: formData.addOnService ? services[formData.addOnService].serviceCleanUpTime : null,
      isDefaultService: false,
      isDefaultRoom: false,
      noteToGuest: service.notesForGuest,
      noteToTherapist: service.notesForTherapist,
      attendeesType: getAttendeeType(formData.clientType),
      teams: formData.clientType !== CLIENTS.GUEST ? [formData.guest] : [],
      external: [],
      fiftyPercentChange: false,
      noCharge: false,
      cancellationNote: null,
      additional: null,
    };
  }, [services, formData, getAttendeeType]);

  const getEventFields = useCallback((id) => {
    const event = events[id];

    return {
      guests: formData.clientType === CLIENTS.GUEST ? [formData.guest] : [],
      room: formData.room,
      status: null,
      purchasePrice: event.purchasePrice,
      salesPrice: event.salesPrice,
      salesTax: event.salesTax,
      duration: event.serviceDuration,
      cleanUp: event.serviceCleanUpTime,
      addOnService: formData.addOnService,
      purchasePriceAddOnService: formData.addOnService ? services[formData.addOnService].purchasePrice : null,
      salesPriceAddOnService: formData.addOnService ? services[formData.addOnService].salesPrice : null,
      salesTaxAddOnService: formData.addOnService ? services[formData.addOnService].salesTax : null,
      durationAddOnService: formData.addOnService ? services[formData.addOnService].serviceDuration : null,
      cleanUpAddOnService: formData.addOnService ? services[formData.addOnService].serviceCleanUpTime : null,
      isDefaultService: false,
      isDefaultRoom: false,
      noteToGuest: event.notesForGuest,
      noteToTherapist: event.notesForTherapist,
      attendeesType: getAttendeeType(formData.clientType),
      teams: formData.clientType !== CLIENTS.GUEST ? [formData.guest] : [],
      external: [],
      fiftyPercentChange: false,
      noCharge: false,
      cancellationNote: null,
      additional: null,
    };
  }, [events, services, formData, getAttendeeType]);

  const validateForm = () => {
    let isValid = true;
    if (!formData.date) {
      isValid = false;
      setErrors((s) => ({ ...s, date: true }));
    }
    if (!formData.time) {
      isValid = false;
      setErrors((s) => ({ ...s, time: true }));
    }
    if (!isAddOn && !formData.service) {
      isValid = false;
      setErrors((s) => ({ ...s, service: true }));
    }
    if (!formData.room) {
      isValid = false;
      setErrors((s) => ({ ...s, room: true }));
    }
    return isValid;
  };

  const handleSave = () => {
    const isValid = validateForm();
    if (!isValid) {
      setAction((s) => ({ ...s, validation: true }));
      return;
    }
    if (appointment && !Object.values(changeLog).length) {
      setAction((s) => ({ ...s, changeLog: true }));
      return;
    }

    const teamSession = Object
      .values(teamSessions)
      .find((ts) => ts.teamId === setup.teamId && ts.sessionId === setup.sessionId && !ts.archived);
    const datePart = moment(formData.date).format('YYYY-MM-DD');
    const timePart = moment(formData.time).format('HH:mm');
    const dateTime = moment(`${datePart} ${timePart}`).format('YYYY-MM-DD HH:mm:ss');

    if (activeTab === APPOINTMENTS.SERVICE) {
      const serviceId = isAddOn ? guestServicesRequests[appointment.id].service : formData.service;
      const serviceFields = getServiceFields(serviceId);

      const data = {
        id: appointment ? +appointment.id : null,
        requestedId: appointment ? +appointment.id : null,
        requestedOperation: appointment ? appointment.requestedOperation : null,
        sessionId: +setup.sessionId,
        serviceProvider: +teamSession.id,
        doneByFsEmployee: formData.doneByFsEmployee,
        dateTime,
        service: serviceId,
        ...serviceFields,
        createdAt: new Date(),
        createdBy: +teamSession.id,
        updatedAt: new Date(),
        updatedBy: +teamSession.id,
      };

      if (!appointment) {
        guestServicesHandler.handleServiceCreate({ ...data, changeLog: { status: 'request created' } }, (done) => {
          setAction((s) => ({ ...s, error: !done, done }));
          if (done) onSave();
        });
      } else {
        guestServicesHandler.handleServiceUpdate({ ...data, changeLog }, (done) => {
          setAction((s) => ({ ...s, error: !done, done }));
          if (done) onSave();
        });
      }
    } else {
      const eventId = isAddOn ? guestEventsRequests[appointment.id].event : formData.event;
      const eventFields = getEventFields(eventId);

      const data = {
        id: appointment ? +appointment.id : null,
        requestedId: appointment ? +appointment.id : null,
        requestedOperation: appointment ? appointment.requestedOperation : null,
        sessionId: +setup.sessionId,
        serviceProvider: +teamSession.id,
        doneByFsEmployee: formData.doneByFsEmployee,
        dateTime,
        event: eventId,
        ...eventFields,
        createdAt: new Date(),
        createdBy: +teamSession.id,
        updatedAt: new Date(),
        updatedBy: +teamSession.id,
      };

      if (!appointment) {
        guestEventsHandler.handleEventCreate({ ...data, changeLog: { status: 'request created' } }, (done) => {
          setAction((s) => ({ ...s, error: !done, done }));
          if (done) onSave();
        });
      } else {
        guestEventsHandler.handleEventUpdate({ ...data, changeLog }, (done) => {
          setAction((s) => ({ ...s, error: !done, done }));
          if (done) onSave();
        });
      }
    }
  };

  const handleCloseMessage = () => setAction({ error: false, done: false, validation: false, changeLog: false });

  if (!services || !teamSessions) return null;

  return (
    <div className={ styles.overlay }>
      <div className={ styles.modal }>
        <div className={ styles.inner }>
          <div className={ styles.header }>
            <div className={ styles.icon } />
            <div className={ styles.heading }>Missed Appointment/Event Request</div>
          </div>
          <div className={ styles.row }>
            <DateGroup
              label="DATE *"
              name="date"
              date={ formData.date ? formData.date.format('YYYY-MM-DD') : null }
              error={ errors.date }
              style={ { width: 200, marginRight: 10 } }
              minDate={ moment(setup.startDate).format('YYYY-MM-DD') }
              maxDate={ moment(setup.endDate).format('YYYY-MM-DD') }
              onChange={ (_, e) => onDateUpdate('date', moment(e)) }
              placeholder="mm/dd/yyyy"
            />
            <TimeGroup
              label="TIME *"
              name="time"
              date={ formData.time ? new Date(formData.time.format('YYYY-MM-DDTHH:mm')) : null }
              error={ errors.time }
              style={ { width: 150, marginRight: 10 } }
              onChange={ (_, e) => onDateUpdate('time', moment(e)) }
              placeholder="--:-- --"
              timeIntervals={ 5 }
            />
            <InputGroup
              label="SERVICE PROVIDER *"
              name="serviceProvider"
              value={ serviceProvider }
              style={ { width: '100%' } }
              onChange={ () => null }
              isDisabled
            />
          </div>
          <div className={ styles.row }>
            <Select
              label="ROOM *"
              name="room"
              placeholder="SELECT ROOM"
              options={ getRoomsOptions }
              style={ { width: 300, marginBottom: 0 } }
              error={ errors.room }
              onChange={ handleSelect }
              selected={ getSelectedRoom }
            />
          </div>
          <div className={ styles.tabContainer }>
            <Tabs tabs={ TABS } activeTab={ activeTab } onTabSelect={ onTabSelect } />
            { activeTab === APPOINTMENTS.SERVICE
              ? (
                <div className={ styles.content }>
                  <Service
                    error={ errors.service }
                    setup={ setup }
                    formData={ formData }
                    disabled={ isAddOn }
                    handleChange={ handleChange }
                    handleSelect={ handleSelect }
                  />
                  <Client
                    setup={ setup }
                    formData={ formData }
                    disabled={ isAddOn }
                    activeTab={ activeClientTab }
                    onTabSelect={ onClientTabSelect }
                    handleChange={ handleChange }
                    handleSelect={ handleSelect }
                  />
                </div>
              )
              : (
                <div className={ styles.content }>
                  <Event
                    error={ errors.service }
                    setup={ setup }
                    formData={ formData }
                    disabled={ isAddOn }
                    handleSelect={ handleSelect }
                  />
                  <Client
                    setup={ setup }
                    formData={ formData }
                    disabled={ isAddOn }
                    activeTab={ activeClientTab }
                    onTabSelect={ onClientTabSelect }
                    handleSelect={ handleSelect }
                  />
                </div>
              )}
          </div>
          <div className={ styles.addOn }>
            <AddOnService formData={ formData } setup={ setup } handleSelect={ handleSelect } />
          </div>
          <div className={ styles.buttonGroup }>
            <div className={ styles.get('btn', 'accept') } onClick={ handleSave }>Save</div>
            <div className={ styles.get('btn', 'cancel') } onClick={ onCancel }>Cancel</div>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={ { vertical: 'bottom', horizontal: 'left' } }
        open={ action.validation }
        autoHideDuration={ 2000 }
        onClose={ handleCloseMessage }
      >
        <Message message="Please fill in the fields marked in Red" variant="error" onClose={ handleCloseMessage } />
      </Snackbar>
      <Snackbar
        anchorOrigin={ { vertical: 'bottom', horizontal: 'left' } }
        open={ action.error }
        autoHideDuration={ 2000 }
        onClose={ handleCloseMessage }
      >
        <Message message="Error" variant="error" onClose={ handleCloseMessage } />
      </Snackbar>
      <Snackbar
        anchorOrigin={ { vertical: 'bottom', horizontal: 'left' } }
        open={ action.changeLog }
        autoHideDuration={ 2000 }
        onClose={ handleCloseMessage }
      >
        <Message message="No changes to save" variant="error" onClose={ handleCloseMessage } />
      </Snackbar>
    </div>
  );
};

Modal.propTypes = {
  setup: PropTypes.shape({
    teamId: PropTypes.number,
    endDate: PropTypes.string,
    startDate: PropTypes.string,
    sessionId: PropTypes.number,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  appointment: PropTypes.shape({
    id: PropTypes.number,
    room: PropTypes.string,
    eventId: PropTypes.number,
    dateTime: PropTypes.string,
    serviceId: PropTypes.number,
    attendeeIds: PropTypes.arrayOf(PropTypes.string),
    addOnService: PropTypes.number,
    attendeeType: PropTypes.string,
    appointmentType: PropTypes.string,
    doneByFsEmployee: PropTypes.bool,
    requestedOperation: PropTypes.string,
  }),
};

Modal.defaultProps = {
  appointment: null,
};

export default Modal;
