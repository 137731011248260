import { api } from '..';

const getTeamAppointments = ({ id, sessionId, startDate, endDate }) => api
  .get('/api/audit/appointmentsByTherapistId', {
    params: {
      id,
      sessionId,
      start: startDate,
      end: endDate,
    },
  });

const getLegend = (cb) => {
  api.get('/api/audit/getColorLegend').then(cb);
};

export default {
  getLegend,
  getTeamAppointments,
};
