import { useState, useEffect } from 'react';

import auditHandler from 'store/effects/audit/handlers';

export default function useLegend() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    auditHandler.getLegend(({ data: { data } }) => {
      setItems(data || []);
    });
  }, []);

  return { legend: items };
}
