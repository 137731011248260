import { useState, useCallback } from 'react';

import guestEventsHandler from 'store/effects/guestEvents/handlers';
import guestServicesHandler from 'store/effects/guestServices/handlers';

export default function useModalNote({ setup, teamSessions, updateAppointments, setAction }) {
  const [modalNote, setModalNote] = useState(null);

  const onNoteToggle = useCallback(({ id, notes, requestedOperation, appointmentType }) => {
    setModalNote({
      id,
      notes,
      appointmentType,
      requestedOperation,
    });
  }, []);

  const onNoteConfirm = useCallback((note) => {
    const data = {
      id: modalNote.id,
      note,
      requestedOperation: modalNote.requestedOperation,
    };

    if (modalNote.appointmentType === 'event') {
      guestEventsHandler.handleNoteUpdate(data, (done) => {
        if (!done) {
          setAction({ done, error: !done });
          return;
        }
        setModalNote(null);
        updateAppointments({ setup, teamSessions });
      });
    } else if (modalNote.appointmentType === 'service') {
      guestServicesHandler.handleNoteUpdate(data, (done) => {
        if (!done) {
          setAction({ done, error: !done });
          return;
        }
        setModalNote(null);
        updateAppointments({ setup, teamSessions });
      });
    }
    setModalNote(null);
  }, [modalNote, setup, teamSessions]);

  const onNoteCancel = useCallback(() => {
    setModalNote(null);
  }, []);

  return {
    modalNote,
    onNoteCancel,
    onNoteToggle,
    onNoteConfirm,
  };
}
