import { dispatch } from 'store';

import {
  editService,
  removeService,
  updateServices,
  editServiceAtomic,
  updateServiceTeams,
} from 'store/actions';

import { api } from '../..';

const getServiceNextId = (cb) => {
  api.get('/api/service/getServiceNextId').then(({ data: { error, id } }) => {
    if (error) cb(null);
    else if (id) cb(id);
  });
};

const getAllServices = () => {
  api.get('/api/service/fetchAllServices').then(({ data }) => {
    if (data.error) dispatch(updateServices({}));
    else dispatch(updateServices({ data: data.data }));
  });
};

const handleServiceCreate = (formData, cb) => {
  api
    .post('api/service/createService', formData)
    .then(({ data: { error, id } }) => {
      if (error) cb({ error: true });
      else {
        const data = {
          [+id]: {
            id: +id,
            ...formData,
          },
        };
        dispatch(updateServices({ data }));
        cb({ error: false, id });
      }
    })
    .catch(() => cb({ error: true }));
};

const handleServiceEdit = (formData, cb) => {
  api
    .post('/api/service/editService', formData)
    .then(({ data: { error, conflicts } }) => {
      if (error) cb({ error: true, done: false, conflicts });
      else {
        const { updateSession, ...rest } = formData;
        dispatch(editService({ data: rest }));
        cb({ error: false, done: true });
      }
    })
    .catch(() => cb({ error: true, done: false }));
};

const handleServiceRemove = ({ id, deletedAt, deletedBy }, cb) => {
  api.post('/api/service/removeService', { id, deletedAt, deletedBy })
    .then(({ data: { error } }) => {
      if (error) cb({ error: true, done: false });
      else {
        dispatch(removeService({ data: { id, deletedAt, deletedBy } }));
        cb({ error: false, done: true });
      }
    });
};

const handleAddTeam = (formData, cb) => {
  const { data, updatedAt, updatedBy } = formData;

  Promise
    .all(data.map((d) => new Promise((resolve, reject) => {
      api.post('/api/service/addTeam', { ...d, updatedAt, updatedBy })
        .then(({ data: { error } }) => {
          if (error) reject();
          else {
            dispatch(updateServiceTeams({ data: { ...d, updatedAt, updatedBy } }));
            resolve();
          }
        });
    })))
    .then(() => cb(true))
    .catch(() => cb(false));
};

const handleRemoveTeam = (formData, cb) => {
  api.post('/api/service/removeTeam', formData)
    .then(({ data: { error } }) => {
      if (error) cb({ error: true, done: false });
      else {
        dispatch(updateServiceTeams({ data: formData }));
        cb({ error: false, done: true });
      }
    });
};

const handleServiceEditAtomic = (formData) => {
  const { id, field, value, updatedAt, updatedBy } = formData;

  api.post('/api/service/editServiceAtomic', { id, field, value, updatedAt, updatedBy })
    .then(({ data: { error } }) => {
      if (!error) {
        dispatch(editServiceAtomic({ id, field, value, updatedAt, updatedBy }));
      }
    });
};

export default {
  handleAddTeam,
  getAllServices,
  getServiceNextId,
  handleRemoveTeam,
  handleServiceEdit,
  handleServiceCreate,
  handleServiceRemove,
  handleServiceEditAtomic,
};
