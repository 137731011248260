import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import guestEventHandler from 'store/effects/guestEvents/handlers';
import guestServiceHandler from 'store/effects/guestServices/handlers';

import { MobileView, DesktopView } from './views';

const VIEWS = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
};

const Audit = () => {
  const params = useParams();
  const history = useHistory();

  const [view, setView] = useState(null);
  const [setup, setSetup] = useState(null);

  useEffect(() => {
    const onResizeHandler = () => setView(window.innerWidth > 450 ? VIEWS.DESKTOP : VIEWS.MOBILE);

    onResizeHandler();
    window.addEventListener('resize', onResizeHandler);

    return () => window.removeEventListener('resize', onResizeHandler);
  }, []);

  useEffect(() => {
    try {
      const { hash } = params;
      const jsonStr = atob(hash);
      const { teamId = null, sessionId = null, startDate = null, endDate = null } = JSON.parse(jsonStr);

      if (!teamId || !sessionId || !startDate || !endDate) {
        history.push('/');
        return;
      }

      setSetup({
        teamId,
        sessionId,
        endDate,
        startDate,
      });

      guestEventHandler.getAllSessionGuestEvents(sessionId);
      guestEventHandler.getAllSessionGuestEventsRequests(sessionId);
      guestServiceHandler.getAllSessionGuestServices(sessionId);
      guestServiceHandler.getAllSessionGuestServicesRequests(sessionId);
    } catch (e) {
      history.push('/');
    }
  }, [params, history]);

  if (!setup || !view) return null;

  const ViewComponent = {
    [VIEWS.MOBILE]: MobileView,
    [VIEWS.DESKTOP]: DesktopView,
  }[view];

  return <ViewComponent setup={ setup } />;
};

export default Audit;
