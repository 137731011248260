import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import auditHandler from 'store/effects/audit/handlers';
import { extendStyles } from 'helpers';

import styles from './styles.scss';

extendStyles(styles);

const ColorLegend = ({ onClose }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    auditHandler.getLegend(({ data: { error, data } }) => {
      setItems(data || []);
    });
  }, []);

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.heading }>Color Legend</div>
      <div className={ styles.close } onClick={ onClose } />
      <div className={ styles.info }><strong>Note:</strong>  The change you’ll request will reflect only when Fresh Start approves them.</div>
      { items.map((item) => (
        <div key={ item.id } className={ styles.row }>
          <div className={ styles.code } style={ { backgroundColor: `${item.color}` } } />
          <div className={ styles.label }>{ item.label }</div>
        </div>
      )) }
    </div>
  );
};

ColorLegend.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ColorLegend;
