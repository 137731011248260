import { dispatch } from 'store';

import { updateGuestServices, updateGuestServicesRequests } from 'store/actions';

import { api } from '..';

const getAllSessionGuestServices = (sessionId) => {
  api.get('/api/guest-service/fetchAllServicesBySession', { params: { sessionId } }).then(({ data: { error, data } }) => {
    if (!error) dispatch(updateGuestServices({ data }));
  });
};

const getAllSessionGuestServicesRequests = (sessionId) => {
  api.get('/api/guest-service/fetchAllRequestsServicesBySession', { params: { sessionId } }).then(({ data: { error, data } }) => {
    if (!error) dispatch(updateGuestServicesRequests({ data }));
  });
};

const handleServiceCreate = (formData, cb) => {
  api
    .post('api/guest-service/createServiceRequest', formData)
    .then(({ data: { error } }) => {
      cb(!error);
    });
};

const handleServiceUpdate = (formData, cb) => {
  api
    .post('api/guest-service/editServiceRequest', formData)
    .then(({ data: { error } }) => {
      cb(!error);
    });
};

const handleServiceDelete = (formData, cb) => {
  api
    .post('api/guest-service/removeServiceRequest', formData)
    .then(({ data: { error } }) => {
      cb(!error);
    });
};

const handleNoteUpdate = (formData, cb) => {
  api
    .post('api/guest-service/updateNoteRequest', formData)
    .then(({ data: { error } }) => {
      cb(!error);
    });
};

export default {
  handleNoteUpdate,
  handleServiceCreate,
  handleServiceUpdate,
  handleServiceDelete,
  getAllSessionGuestServices,
  getAllSessionGuestServicesRequests,
};
