import { dispatch } from 'store';

import {
  editEvent,
  removeEvent,
  updateEvents,
  editEventAtomic,
  updateEventTeams,
} from 'store/actions';

import { api } from '../..';

const getEventNextId = (cb) => {
  api.get('/api/event/getEventNextId').then(({ data: { error, id } }) => {
    if (error) cb(null);
    else if (id) cb(id);
  });
};

const getAllEvents = () => {
  api.get('/api/event/fetchAllEvents').then(({ data }) => {
    if (data.error) dispatch(updateEvents({}));
    else dispatch(updateEvents({ data: data.data }));
  });
};

const handleEventCreate = (formData, cb) => {
  api
    .post('api/event/createEvent', formData)
    .then(({ data: { error, id } }) => {
      if (error) cb({ error: true });
      else {
        const data = {
          [+id]: {
            id: +id,
            ...formData,
          },
        };
        dispatch(updateEvents({ data }));
        cb({ error: false, id });
      }
    })
    .catch(() => cb({ error: true }));
};

const handleEventEdit = (formData, cb) => {
  api
    .post('/api/event/editEvent', formData)
    .then(({ data: { error } }) => {
      if (error) cb({ error: true, done: false });
      else {
        const { updateSession, ...rest } = formData;
        dispatch(editEvent({ data: rest }));
        cb({ error: false, done: true });
      }
    })
    .catch(() => cb({ error: true, done: false }));
};

const handleEventRemove = ({ id, deletedAt, deletedBy }, cb) => {
  api.post('/api/event/removeEvent', { id, deletedAt, deletedBy })
    .then(({ data: { error } }) => {
      if (error) cb({ error: true, done: false });
      else {
        dispatch(removeEvent({ data: { id, deletedAt, deletedBy } }));
        cb({ error: false, done: true });
      }
    });
};

const handleAddTeam = (formData, cb) => {
  const { data, updatedAt, updatedBy } = formData;

  Promise
    .all(data.map((d) => new Promise((resolve, reject) => {
      api.post('/api/event/addTeam', { ...d, updatedAt, updatedBy })
        .then(({ data: { error } }) => {
          if (error) reject();
          else {
            dispatch(updateEventTeams({ data: { ...d, updatedAt, updatedBy } }));
            resolve();
          }
        });
    })))
    .then(() => cb(true))
    .catch(() => cb(false));
};

const handleRemoveTeam = (formData, cb) => {
  api.post('/api/event/removeTeam', formData)
    .then(({ data: { error } }) => {
      if (error) cb({ error: true, done: false });
      else {
        dispatch(updateEventTeams({ data: formData }));
        cb({ error: false, done: true });
      }
    });
};

const handleEventEditAtomic = (formData) => {
  const { id, field, value, updatedAt, updatedBy } = formData;

  api.post('/api/event/editEventAtomic', { id, field, value, updatedAt, updatedBy })
    .then(({ data: { error } }) => {
      if (!error) {
        dispatch(editEventAtomic({ id, field, value, updatedAt, updatedBy }));
      }
    });
};

export default {
  handleAddTeam,
  getAllEvents,
  getEventNextId,
  handleEventEdit,
  handleRemoveTeam,
  handleEventCreate,
  handleEventRemove,
  handleEventEditAtomic,
};
