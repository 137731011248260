/* eslint-disable max-len */
import React, { useState, useMemo, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Snackbar from '@material-ui/core/Snackbar';

import { Message } from 'components';
import { useData, useLegend } from 'hooks';
import { extendStyles, formatCurrency } from 'helpers';

import { Form } from './components';
import { ColorLegend, ModalDelete, ModalNote } from '../../components';
import { useAppointments, useModalCreate, useModalUpdate, useModalDelete, useModalNote } from '../../hooks';

import styles from './styles.scss';

extendStyles(styles);

const MobileView = ({ setup }) => {
  const [action, setAction] = useState({ error: false, done: false });
  const [showLegend, setLegend] = useState(false);
  const [showPanel, setShowPanel] = useState(null);

  const { legend } = useLegend();
  const { teams, teamSessions } = useData();
  const { appointments, updateAppointments } = useAppointments({ setup, teamSessions });
  const {
    modalNote,
    onNoteCancel,
    onNoteToggle,
    onNoteConfirm } = useModalNote({ setup, teamSessions, updateAppointments, setAction });
  const {
    modalCreate,
    onCreateCancel,
    onCreateToggle,
    onCreateConfirm } = useModalCreate({ setup, teamSessions, updateAppointments });
  const {
    modalUpdate,
    onUpdateCancel,
    onUpdateToggle,
    onUpdateConfirm } = useModalUpdate({ setup, teamSessions, appointments, updateAppointments });
  const {
    modalDelete,
    onDeleteCancel,
    onDeleteToggle,
    onDeleteConfirm } = useModalDelete({ setup, teamSessions, appointments, updateAppointments, setAction });

  const teamName = useMemo(() => {
    if (!teams || !teams[setup.teamId]) return null;
    const { firstName, lastName } = teams[setup.teamId];
    return `${firstName} ${lastName}`;
  }, [teams, setup]);

  const openLegend = useCallback(() => { setLegend(true); }, []);
  const closeLegend = useCallback(() => { setLegend(false); }, []);
  const togglePanel = useCallback((id) => { setShowPanel((prev) => (prev === id ? null : id)); }, []);

  const getAppointmentName = useCallback((appt) => {
    if (!appt.addOnServiceId) return appt.name;
    return (
      <>
        <b>AddOn:</b>
        {appt.name}
      </>
    );
  }, []);

  const isAppointmentHasRequest = useCallback((appt) => {
    if (appt.requestedOperation !== undefined) return true;
    const requestedAppointment = (appointments || []).find((item) => +item.requestedId === +appt.id);
    return requestedAppointment === undefined;
  }, [appointments]);

  const rowStyles = useMemo(() => (legend || [])
    .reduce((res, { color, label }) => {
      if (label.indexOf('add') !== -1) return { ...res, '--create': color };
      if (label.indexOf('edit') !== -1) return { ...res, '--update': color };
      if (label.indexOf('deleted') !== -1) return { ...res, '--delete': color };
      if (label.indexOf('unchanged') !== -1) return { ...res, '--unchanged': color };
      return res;
    }, {}), [legend]);

  const renderAppointment = (appt) => {
    let key = appt.addOnServiceId ? `${appt.id}-addOn` : appt.id;
    if (appt.requestedOperation !== undefined) key += '-request';

    return (
      <Fragment key={ key }>
        <div className={ styles.appointment } onClick={ () => togglePanel(appt.id) }>
          <div
            className={ styles.get('sidebar', appt.requestedOperation || '') }
            style={ rowStyles }
          />
          <div className={ styles.column }>
            <div className={ styles.get('row', 'inline') }>
              <div className={ styles.time }>{ moment(appt.dateTime).format('HH:mm A') }</div>
              <div className={ styles.name }>{ getAppointmentName(appt) }</div>
            </div>
            <div className={ styles.get('row', 'between') }>
              <div className={ styles.client }>{ appt.guest }</div>
              <div className={ styles.cost }>{ formatCurrency(appt.price) }</div>
            </div>
          </div>
        </div>
        { showPanel === appt.id && (
          <div className={ styles.panel }>
            <div className={ styles.presser } onClick={ onCreateToggle }>
              <div className={ styles.button }>
                <div className={ styles.create } />
              </div>
            </div>
            <div className={ styles.presser } onClick={ () => onNoteToggle(appt) }>
              <div className={ styles.button }>
                <div className={ appt.notes ? styles.updateNote : styles.addNote } />
              </div>
            </div>
            <div
              className={ styles.get('presser', appt.requestedOperation === 'delete' ? 'disabled' : '') }
              onClick={ () => onUpdateToggle(appt) }
            >
              <div className={ styles.button }>
                <div className={ styles.update } />
              </div>
            </div>
            <div
              className={ styles.presser }
              onClick={ () => onDeleteToggle(appt) }
            >
              <div className={ styles.button }>
                <div className={ styles.delete } />
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  };

  const handleCloseMessage = () => setAction({ error: false, done: false });

  if (!teamName) return null;

  if (modalCreate) {
    return (
      <Form
        setup={ setup }
        onSave={ onCreateConfirm }
        onClose={ onCreateCancel }
      />
    );
  }

  if (modalUpdate) {
    return (
      <Form
        setup={ setup }
        onSave={ onUpdateConfirm }
        onClose={ onUpdateCancel }
        appointment={ modalUpdate }
      />
    );
  }

  return (
    <div className={ styles.container }>
      <div className={ styles.label }>
        Instructions: Click to
        <strong>Add,</strong>
        <strong>Edit,</strong>
        <strong>Add Note</strong>
        or<strong>Delete</strong>
      </div>
      <div className={ styles.legend } onClick={ openLegend } />
      <div className={ styles.list }>
        {Object
          .values(appointments || [])
          .filter((appt) => appt.addOnServiceId === undefined && isAppointmentHasRequest(appt))
          .sort((a, b) => {
            if (moment(a.dateTime).isBefore(moment(b.dateTime))) return -1;
            if (moment(b.dateTime).isBefore(moment(a.dateTime))) return 1;
            return 0;
          })
          .map(renderAppointment)}
      </div>
      <div className={ styles.note }><strong>NOTE:</strong> After check out been completed, you will not be reimbursed for any changes or missed appointments. We tried, it ruins the realationship with the guest, when they find out they owe, yet more. However if you find the charge for the service that was not performed, we&apos;ll reimburse the guest.</div>
      <div className={ styles.note }>Review of submited notes by Fresh Start: if they&apos;ve been prior approved by the Guest, we&apos;ll approve the update. If we have questions, we&apos;ll reach out to you. Please be very spefic when leaving the notes.</div>
      <div className={ styles.note }>I&apos;ve reviewed all appointments and I agree to the terms above.</div>
      <div className={ styles.note }>Please do the audits ON TIME :)</div>
      { showLegend && <ColorLegend onClose={ closeLegend } /> }
      { modalNote && (
        <ModalNote
          notes={ modalNote.notes }
          isMobile
          onCancel={ onNoteCancel }
          onConfirm={ onNoteConfirm }
        />
      )}
      { modalDelete && (
        <ModalDelete
          type="warning"
          value={ modalDelete.name }
          query={ modalDelete.requestedOperation === 'delete' ? 'Restore' : 'Delete' }
          onCancel={ onDeleteCancel }
          onConfirm={ onDeleteConfirm }
          labelPrimary={ modalDelete.requestedOperation === 'delete' ? 'Are you sure you want to restore' : 'Are you sure you want to request the deletion of' }
          labelSecondary="Appointment: "
          isRequireAction={ false }
        />
      )}
      <Snackbar
        anchorOrigin={ { vertical: 'bottom', horizontal: 'left' } }
        open={ action.done }
        autoHideDuration={ 2000 }
        onClose={ handleCloseMessage }
      >
        <Message message="Success" variant="success" onClose={ handleCloseMessage } />
      </Snackbar>
      <Snackbar
        anchorOrigin={ { vertical: 'bottom', horizontal: 'left' } }
        open={ action.error }
        autoHideDuration={ 2000 }
        onClose={ handleCloseMessage }
      >
        <Message message="Error" variant="error" onClose={ handleCloseMessage } />
      </Snackbar>
    </div>
  );
};

MobileView.propTypes = {
  setup: PropTypes.shape({
    teamId: PropTypes.number,
  }).isRequired,
};

export default MobileView;
