import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Select } from 'components';
import { useData } from 'hooks';
import { extendStyles, formatCurrency } from 'helpers';

import { prepareSelect } from './helpers';

import styles from './styles.scss';

extendStyles(styles);

const AddOnService = ({ formData, error, handleSelect }) => {
  const { services } = useData();
  
  const getServiceName = useCallback((id) => {
    if (!services || !services[id]) return null;
    return services[id].billingDescription;
  }, [services]);

  const getServiceDuration = useCallback((id) => {
    if (!services || !services[id]) return null;
    return services[id].serviceDuration ? `${services[id].serviceDuration} min` : '';
  }, [services]);

  const getServiceCleanUp = useCallback((id) => {
    if (!services || !services[id]) return null;
    return services[id].serviceCleanUpTime ? `${services[id].serviceCleanUpTime} min` : '';
  }, [services]);

  const servicePrice = useMemo(() => {
    if (!services || !formData.addOnService || !services[formData.addOnService]) return null;
    return formatCurrency(services[formData.addOnService].purchasePrice);
  }, [services, formData.addOnService]);

  return (
    <div className={ styles.card }>
      <div className={ styles.row }>
        <div className={ styles.get('label', 'fixed') }>Add on</div>
        <Select
          name="addOnService"
          error={ error }
          style={ { width: '100%', marginRight: 0, marginBottom: 0 } }
          options={ prepareSelect({ data: services }) }
          selected={ getServiceName(formData.addOnService) }
          onChange={ handleSelect }
          placeholder="Select Service"
        />
      </div>
      <div className={ styles.row }>
        <div className={ styles.group }>
          <div className={ styles.label }>Duration</div>
          <div className={ styles.value }>{ getServiceDuration(formData.addOnService) }</div>
        </div>
        <div className={ styles.group }>
          <div className={ styles.label }>Clean up</div>
          <div className={ styles.value }>{ getServiceCleanUp(formData.addOnService) }</div>
        </div>
      </div>
      <div className={ styles.row }>
        <div className={ styles.label }>Rate</div>
        <div className={ styles.value }>{ `${servicePrice || '$PurchasePrice'}` }</div>
      </div>
    </div>
  );
};

AddOnService.propTypes = {
  error: PropTypes.bool.isRequired,
  formData: PropTypes.shape({ service: PropTypes.number }).isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default AddOnService;
