import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useData } from 'hooks';
import { formatCurrency } from 'helpers';
import { Select, InputGroup, Checkbox } from 'components';

import { prepareSelect } from './helpers';

import styles from './styles.scss';

const Event = ({ formData, setup, error, disabled, handleChange, handleSelect }) => {
  const { events } = useData();

  const getEventName = useCallback((id) => {
    if (!events || !events[id]) return null;
    return events[id].billingDescription;
  }, [events]);

  const getEventDuration = useCallback((id) => {
    if (!events || !events[id]) return null;
    return events[id].eventDuration;
  }, [events]);

  const getEventCleanUp = useCallback((id) => {
    if (!events || !events[id]) return null;
    return events[id].eventCleanUpTime;
  }, [events]);

  const eventPrice = useMemo(() => {
    if (!events || !formData.event || !events[formData.event]) return null;
    return formatCurrency(events[formData.event].purchasePrice);
  }, [events, formData.event]);

  return (
    <div className={ styles.card }>
      <div className={ styles.row }>
        <Select
          name="event"
          label="EVENT *"
          error={ error }
          style={ { width: '100%' } }
          options={ prepareSelect({ data: events, teamId: setup.teamId }) }
          selected={ getEventName(formData.event) }
          disabled={ disabled }
          onChange={ handleSelect }
          placeholder="Select Event"
        />
      </div>
      <div className={ styles.price }>
        { `Rate: ${eventPrice || '$PurchasePrice'}` }
      </div>
      <div className={ styles.row }>
        <InputGroup
          label="DURATION"
          name="duration"
          value={ getEventDuration(formData.event) }
          style={ { width: 100, marginRight: 20 } }
          onChange={ () => null }
          isDisabled
        />
        <InputGroup
          label="CLEAN UP"
          name="cleanUp"
          value={ getEventCleanUp(formData.event) }
          style={ { width: 100 } }
          onChange={ () => null }
          isDisabled
        />
        <div className={ styles.row }>
          <Checkbox
            id="doneByFsEmployee"
            name="doneByFsEmployee"
            label="DONE BY FS EMPLOYEE"
            style={ { marginBottom: 5 } }
            onChange={ handleChange }
            checked={ formData.doneByFsEmployee }
          />
        </div>
      </div>
    </div>
  );
};

Event.propTypes = {
  error: PropTypes.bool.isRequired,
  setup: PropTypes.shape({ teamId: PropTypes.number }).isRequired,
  formData: PropTypes.shape({
    event: PropTypes.number,
    doneByFsEmployee: PropTypes.bool,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default Event;
