import { dispatch } from 'store';

import {
  removeVisitor,
  updateVisitors,
} from 'store/actions';

import { api } from '..';

const getAllVisitors = () => {
  api.get('/api/visitor/fetchAllVisitors').then(({ data }) => {
    if (data.error) dispatch(updateVisitors({}));
    else dispatch(updateVisitors({ data: data.data }));
  });
};

const handleVisitorRemove = ({ id, deletedAt, deletedBy }, cb) => {
  api.post('/api/visitor/removeVisitor', { id, deletedAt, deletedBy })
    .then(({ data: { error } }) => {
      if (error) cb({ error: true, done: false });
      else {
        dispatch(removeVisitor({ data: { id, deletedAt, deletedBy } }));
        cb({ error: false, done: true });
      }
    });
};

export default {
  getAllVisitors,
  handleVisitorRemove,
};
