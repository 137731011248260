/* eslint-disable max-len */
import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Select } from 'components';
import { useData } from 'hooks';
import { formatCurrency } from 'helpers';

import styles from './styles.scss';

const AddOnService = ({ setup, formData, handleSelect }) => {
  const { services } = useData();

  const getServiceName = useCallback((id) => {
    if (!services || !services[id]) return null;
    return services[id].billingDescription;
  }, [services]);

  const serviceOptions = useMemo(() => {
    if (services && setup.teamId) {
      return Object
        .values(services)
        .filter((service) => (service.isAddOn && !service.archived && service.teams.find((item) => +item.id === +setup.teamId)))
        .map((service) => ({ id: service.id, value: service.billingDescription || service.serviceName }));
    }
    return [];
  }, [setup.teamId, services]);

  const servicePrice = useMemo(() => {
    if (!services || !formData.addOnService || !services[formData.addOnService]) return null;
    return formatCurrency(services[formData.addOnService].purchasePrice);
  }, [services, formData.addOnService]);

  return (
    <div className={ styles.card }>
      <div className={ styles.row }>
        <Select
          name="addOnService"
          label="ADD-ON SERVICE"
          style={ { width: 300 } }
          options={ serviceOptions }
          selected={ getServiceName(formData.addOnService) }
          onChange={ handleSelect }
          placeholder="Select Service"
        />
        <div className={ styles.info }>I.e. B12 Shot, Adrenal Shot<strong>Note:</strong> It can be done during the appointment</div>
      </div>
      <div className={ styles.price }>
        { `Rate: ${servicePrice || '$PurchasePrice'}` }
      </div>
    </div>
  );
};

AddOnService.propTypes = {
  setup: PropTypes.shape({
    teamId: PropTypes.number,
  }).isRequired,
  formData: PropTypes.shape({
    addOnService: PropTypes.number,
  }).isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default AddOnService;
