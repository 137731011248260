/* eslint-disable no-console */
import { useState, useEffect, useMemo, useCallback } from 'react';
import moment from 'moment';

import auditHandler from 'store/effects/audit/handlers';

export default function useAppointments({ setup, teamSessions }) {
  const [appointments, setAppointments] = useState(null);

  const updateAppointments = useCallback(({ setup: teamSetup, teamSessions: tSessions }) => {
    const teamSession = Object
      .values(tSessions || {})
      .find((ts) => ts.teamId === teamSetup.teamId && ts.sessionId === teamSetup.sessionId && !ts.archived);

    if (!teamSession) return;

    auditHandler
      .getTeamAppointments({
        id: teamSession.id,
        sessionId: teamSetup.sessionId,
        startDate: moment(teamSetup.startDate).format('YYYY-MM-DD'),
        endDate: moment(teamSetup.endDate).format('YYYY-MM-DD'),
      })
      .then(({ data: { data = [] } }) => {
        setAppointments(data);
      })
      .catch((e) => {
        console.log('Team appointments error', e);
      });
  }, []);

  useEffect(() => {
    if (!setup || !Object.values(teamSessions).length) return;
    updateAppointments({ setup, teamSessions });
  }, [setup, teamSessions, updateAppointments]);

  const appointmentDates = useMemo(() => (appointments || [])
    .filter((apt) => apt.addOnServiceId === undefined)
    .sort((a, b) => {
      const dateA = moment(a.dateTime);
      const dateB = moment(b.dateTime);
      if (dateA.isBefore(dateB)) return -1;
      if (dateA.isAfter(dateB)) return 1;
      return 0;
    })
    .reduce((res, { dateTime }) => ({
      ...res,
      [moment(dateTime).format('YYYY-MM-DD')]: moment(dateTime).format('YYYY-MM-DD'),
    }), {}), [appointments]);

  return { appointments, appointmentDates, updateAppointments };
}
