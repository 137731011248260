import { useState, useCallback } from 'react';

export default function useModalCreate({ setup, teamSessions, updateAppointments }) {
  const [modalCreate, setModalCreate] = useState(null);
  const [modalConfirm, setModalConfirm] = useState(null);

  const onCreateToggle = useCallback(() => {
    setModalCreate(true);
  }, []);

  const onCreateConfirm = useCallback(() => {
    setModalCreate(null);
    setModalConfirm(true);
    setTimeout(() => setModalConfirm(null), 3000);
    updateAppointments({ setup, teamSessions });
  }, [setup, teamSessions, updateAppointments]);

  const onCreateCancel = useCallback(() => {
    setModalCreate(null);
  }, []);

  return {
    modalCreate,
    modalConfirm,
    onCreateCancel,
    onCreateToggle,
    onCreateConfirm,
  };
}
