import { useState, useCallback } from 'react';

import guestEventsHandler from 'store/effects/guestEvents/handlers';
import guestServicesHandler from 'store/effects/guestServices/handlers';

export default function useModalDelete({ setup, teamSessions, appointments, updateAppointments, setAction }) {
  const [modalDelete, setModalDelete] = useState(null);
  const [modalConfirm, setModalConfirm] = useState(null);

  const getAppointmentName = useCallback((id) => {
    const appointment = appointments.find((appt) => appt.id === id && appt.addOnServiceId === undefined);
    const { name, dateTime: date, guest: client } = appointment;
    return `${name} on ${date} for ${client}`;
  }, [appointments]);

  const onDeleteToggle = useCallback(({ id, requestedOperation, appointmentType }) => {
    setModalDelete({
      id,
      name: getAppointmentName(id),
      appointmentType,
      requestedOperation,
    });
  }, [getAppointmentName]);

  const onDeleteConfirm = useCallback(() => {
    const teamSession = Object
      .values(teamSessions)
      .find((ts) => +ts.teamId === +setup.teamId && +ts.sessionId === +setup.sessionId && !ts.archived);

    const data = {
      id: modalDelete.id,
      requestedOperation: modalDelete.requestedOperation,
      deletedAt: new Date(),
      deletedBy: +teamSession.id,
    };

    if (modalDelete.appointmentType === 'event') {
      guestEventsHandler.handleEventDelete(data, (done) => {
        if (!done) {
          setAction({ done, error: !done });
          return;
        }
        setModalDelete(null);
        setModalConfirm(true);
        setTimeout(() => setModalConfirm(null), 3000);
        updateAppointments({ setup, teamSessions });
      });
    } else if (modalDelete.appointmentType === 'service') {
      guestServicesHandler.handleServiceDelete(data, (done) => {
        if (!done) {
          setAction({ done, error: !done });
          return;
        }
        setModalDelete(null);
        setModalConfirm(true);
        setTimeout(() => setModalConfirm(null), 3000);
        updateAppointments({ setup, teamSessions });
      });
    }
    setModalDelete(null);
  }, [modalDelete, setAction, setup, teamSessions, updateAppointments]);

  const onDeleteCancel = useCallback(() => {
    setModalDelete(null);
  }, []);

  return {
    modalDelete,
    modalConfirm,
    onDeleteCancel,
    onDeleteToggle,
    onDeleteConfirm,
  };
}
