import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useData } from 'hooks';
import { Select, Checkbox } from 'components';
import { extendStyles, formatCurrency } from 'helpers';

import { prepareSelect } from './helpers';

import styles from './styles.scss';

extendStyles(styles);

const Service = ({ formData, setup, error, handleChange, handleSelect }) => {
  const { services } = useData();

  const getServiceName = useCallback((id) => {
    if (!services || !services[id]) return null;
    return services[id].billingDescription;
  }, [services]);

  const getServiceDuration = useCallback((id) => {
    if (!services || !services[id]) return null;
    return services[id].serviceDuration ? `${services[id].serviceDuration} min` : '';
  }, [services]);

  const getServiceCleanUp = useCallback((id) => {
    if (!services || !services[id]) return null;
    return services[id].serviceCleanUpTime ? `${services[id].serviceCleanUpTime} min` : '';
  }, [services]);

  const servicePrice = useMemo(() => {
    if (!services || !formData.service || !services[formData.service]) return null;
    return formatCurrency(services[formData.service].purchasePrice);
  }, [services, formData.service]);

  return (
    <div className={ styles.card }>
      <div className={ styles.row }>
        <div className={ styles.get('label', 'fixed') }>Service</div>
        <Select
          name="service"
          error={ error }
          style={ { width: '100%', marginRight: 0, marginBottom: 0 } }
          options={ prepareSelect({ data: services, teamId: setup.teamId }) }
          selected={ getServiceName(formData.service) }
          onChange={ handleSelect }
          placeholder="Select Service"
        />
      </div>
      <div className={ styles.row }>
        <div className={ styles.group }>
          <div className={ styles.label }>Duration</div>
          <div className={ styles.value }>{ getServiceDuration(formData.service) }</div>
        </div>
        <div className={ styles.group }>
          <div className={ styles.label }>Clean up</div>
          <div className={ styles.value }>{ getServiceCleanUp(formData.service) }</div>
        </div>
      </div>
      <div className={ styles.row }>
        <div className={ styles.label }>Rate</div>
        <div className={ styles.value }>{ `${servicePrice || '$PurchasePrice'}` }</div>
      </div>
      <div className={ styles.row }>
        <Checkbox
          id="doneByFsEmployee"
          name="doneByFsEmployee"
          label="DONE BY FS EMPLOYEE"
          style={ { marginBottom: 5 } }
          onChange={ handleChange }
          checked={ formData.doneByFsEmployee }
        />
      </div>
    </div>
  );
};

Service.propTypes = {
  error: PropTypes.bool.isRequired,
  setup: PropTypes.shape({ teamId: PropTypes.number }).isRequired,
  formData: PropTypes.shape({
    service: PropTypes.number,
    doneByFsEmployee: PropTypes.bool,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default Service;
