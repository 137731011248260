/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const Checkbox = ({ name, label, checked, style, onChange, disabled }) => (
  <div className={ styles.checkGroup } style={ style }>
    <input
      id={ name }
      name={ name }
      type="checkbox"
      checked={ checked }
      onChange={ onChange }
      className={ styles.cb }
      disabled={ disabled }
    />
    <label htmlFor={ name } className={ styles.label }>{ label }</label>
  </div>
);

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  style: PropTypes.shape({}),
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  style: {},
  label: null,
  checked: false,
  disabled: false,
};

export default Checkbox;
