import guestSessionHandler from 'store/effects/guestSession/handlers';

export const fetchGuestSession = ({ jsonData, updateError }) => {
  guestSessionHandler.handleGetGuestSessionById(jsonData.id, () => {
    let message = '';

    switch (jsonData.message) {
      case 'create-guest-session':
        message = 'Guest Session added.';
        break;
      case 'edit-guest-session':
        message = 'Guest Session updated.';
        break;
      case 'delete-guest-session':
        message = 'Guest Session removed.';
        break;
      default:
        break;
    }

    updateError({
      open: true,
      message,
    });
  });
};
