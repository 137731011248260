import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useData } from 'hooks';
import { Select } from 'components';

import { Tabs } from './components';
import { CLIENTS, prepareSelect } from './helpers';

import styles from './styles.scss';

const TABS = [
  { id: CLIENTS.GUEST, label: 'GUEST' },
  { id: CLIENTS.TEAM, label: 'TEAM' },
  { id: CLIENTS.VISITOR, label: 'VISITOR' },
];

const Client = ({ formData, setup, activeTab, handleSelect, disabled, onTabSelect }) => {
  const { guests, teams, visitors, guestSessions, options } = useData();

  const getClientName = useCallback((id) => {
    const data = {
      [CLIENTS.TEAM]: teams,
      [CLIENTS.GUEST]: guestSessions,
      [CLIENTS.VISITOR]: visitors,
    }[activeTab];

    if (!data || !data[id]) return null;

    if (activeTab === CLIENTS.GUEST) {
      const { guestId } = guestSessions[id];
      const { firstName, lastName } = guests[guestId];

      return `${firstName} ${lastName}`;
    }

    const { firstName, lastName } = data[id];

    return `${firstName} ${lastName}`;
  }, [guests, teams, visitors, guestSessions, activeTab]);

  const selectLabel = useMemo(() => {
    if (activeTab === CLIENTS.GUEST) return 'Guest';
    if (activeTab === CLIENTS.TEAM) return 'Team';
    if (activeTab === CLIENTS.VISITOR) return 'Visitor';
    return null;
  }, [activeTab]);

  return (
    <div className={ styles.card }>
      <Tabs tabs={ TABS } activeTab={ activeTab } onTabSelect={ onTabSelect } />
      <Select
        name="guest"
        label={ selectLabel }
        style={ { width: '100%' } }
        options={ prepareSelect({ guests, teams, visitors, activeTab, guestSessions, options, setup }) }
        selected={ getClientName(formData.guest) }
        disabled={ disabled }
        onChange={ handleSelect }
        placeholder={ `Select ${selectLabel}` }
      />
    </div>
  );
};

Client.propTypes = {
  setup: PropTypes.shape({ sessionId: PropTypes.number }).isRequired,
  formData: PropTypes.shape({ guest: PropTypes.number }).isRequired,
  disabled: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  onTabSelect: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default Client;
