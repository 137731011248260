import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import { extendStyles } from 'helpers';

import styles from './styles.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

extendStyles(styles);

const TimeGroup = ({ name, date, label, placeholder, timeIntervals, style, onChange,
  isMobile, isDisabled, minTime, maxTime, error }) => {
  const onTimeChange = (val) => onChange(name, val);

  return (
    <div className={ styles.wrapper } style={ style }>
      {label
      && (
      <div className={ styles.labelGroup }>
        <div className={ styles.label }>{ label }</div>
      </div>
      )}
      <DatePicker
        selected={ date }
        onChange={ onTimeChange }
        className={ styles.get('datePicker', error && 'error', isMobile && 'mobile') }
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={ timeIntervals }
        dateFormat="h:mm aa"
        placeholderText={ placeholder }
        disabled={ isDisabled }
        minTime={ minTime }
        maxTime={ maxTime }
      />
    </div>
  );
};

TimeGroup.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date),
  error: PropTypes.bool,
  label: PropTypes.string,
  style: PropTypes.shape({}),
  minTime: PropTypes.shape({}),
  maxTime: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  timeIntervals: PropTypes.number,
};

TimeGroup.defaultProps = {
  date: null,
  error: false,
  label: null,
  style: {},
  minTime: null,
  maxTime: null,
  isMobile: false,
  isDisabled: false,
  timeIntervals: 15,
};

export default TimeGroup;
