import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import DatePicker from 'react-datepicker';

import { extendStyles } from 'helpers';

import styles from './styles.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

extendStyles(styles);

const DateGroup = ({ name, date, label, error, minDate, maxDate, placeholder, style, onChange, isDisabled, isMobile }) => {
  const handleChange = (e) => {
    onChange(name, e);
  };

  return (
    <div className={ styles.wrapper } style={ style }>
      { label && (
        <div className={ styles.labelGroup }>
          <div className={ styles.label }>{ label }</div>
        </div>
      )}
      { maxDate
        ? (
          <DatePicker
            selected={ date ? new Date(moment(date).format('YYYY/MM/DD')) : null }
            minDate={ minDate ? new Date(moment(minDate).format('YYYY/MM/DD')) : null }
            maxDate={ maxDate ? new Date(moment(maxDate).format('YYYY/MM/DD')) : null }
            onChange={ handleChange }
            className={ styles.get('datePicker', error && 'error', isMobile && 'mobile') }
            placeholderText={ placeholder }
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            disabled={ isDisabled }
          />
        )
        : (
          <DatePicker
            selected={ date ? new Date(moment(date).format('YYYY/MM/DD')) : null }
            onChange={ handleChange }
            className={ styles.get('datePicker', error && 'error', isMobile && 'mobile') }
            placeholderText={ placeholder }
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            disabled={ isDisabled }
          />
        )}
    </div>
  );
};

DateGroup.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  style: PropTypes.shape({}),
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

DateGroup.defaultProps = {
  date: null,
  label: null,
  error: false,
  style: {},
  minDate: null,
  maxDate: null,
  isMobile: false,
  isDisabled: false,
};

export default DateGroup;
