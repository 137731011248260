import { dispatch } from 'store';

import { updateGuestEvents, updateGuestEventsRequests } from 'store/actions';

import { api } from '..';

const getAllSessionGuestEvents = (sessionId) => {
  api.get('/api/guest-event/fetchAllEventsBySession', { params: { sessionId } }).then(({ data: { error, data } }) => {
    if (!error) dispatch(updateGuestEvents({ data }));
  });
};

const getAllSessionGuestEventsRequests = (sessionId) => {
  api.get('/api/guest-event/fetchAllRequestsEventsBySession', { params: { sessionId } }).then(({ data: { error, data } }) => {
    if (!error) dispatch(updateGuestEventsRequests({ data }));
  });
};

const handleEventCreate = (formData, cb) => {
  api
    .post('api/guest-event/createEventRequest', formData)
    .then(({ data: { error } }) => {
      cb(!error);
    });
};

const handleEventUpdate = (formData, cb) => {
  api
    .post('api/guest-event/editEventRequest', formData)
    .then(({ data: { error } }) => {
      cb(!error);
    });
};

const handleEventDelete = (formData, cb) => {
  api
    .post('api/guest-event/removeEventRequest', formData)
    .then(({ data: { error } }) => {
      cb(!error);
    });
};

const handleNoteUpdate = (formData, cb) => {
  api
    .post('api/guest-event/updateNoteRequest', formData)
    .then(({ data: { error } }) => {
      cb(!error);
    });
};

export default {
  handleNoteUpdate,
  handleEventCreate,
  handleEventUpdate,
  handleEventDelete,
  getAllSessionGuestEvents,
  getAllSessionGuestEventsRequests,
};
