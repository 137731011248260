/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const RadioGroup = ({ name, label, value, checked, style, onChange, isDisabled }) => (
  <div className={ styles.checkGroup } style={ style }>
    <input
      id={ name }
      name={ name }
      type="radio"
      value={ value }
      checked={ checked }
      onChange={ onChange }
      disabled={ isDisabled }
      className={ styles.cb }
    />
    <label htmlFor={ name } className={ styles.label }>{ label }</label>
  </div>
);

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  style: PropTypes.shape({}),
  value: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

RadioGroup.defaultProps = {
  style: {},
  label: null,
  checked: false,
  isDisabled: false,
};

export default RadioGroup;
