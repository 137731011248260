export const CLIENTS = {
  GUEST: 'Guest',
  TEAM: 'Team',
  VISITOR: 'Visitor',
};

export const prepareSelect = ({ guests, teams, visitors, activeTab, guestSessions, setup }) => {
  const data = {
    [CLIENTS.GUEST]: guests,
    [CLIENTS.TEAM]: teams,
    [CLIENTS.VISITOR]: visitors,
  }[activeTab];

  if (activeTab === CLIENTS.GUEST) {
    return Object
      .values(guestSessions || {})
      .filter((gs) => +gs.sessionId === +setup.sessionId && !gs.archived)
      .map((gs) => ({ id: +gs.id, value: `${guests[gs.guestId].firstName} ${guests[gs.guestId].lastName}` }))
      .sort((a, b) => {
        if (a.value < b.value) return -1;
        if (b.value < a.value) return 1;
        return 0;
      });
  }

  return Object
    .values(data || {})
    .filter((item) => !item.archived)
    .map((item) => ({ id: +item.id, value: `${item.firstName} ${item.lastName}` }))
    .sort((a, b) => {
      if (a.value < b.value) return -1;
      if (b.value < a.value) return 1;
      return 0;
    });
};
