import { useState, useCallback } from 'react';

export default function useModalUpdate({ setup, teamSessions, appointments, updateAppointments }) {
  const [modalUpdate, setModalUpdate] = useState(null);
  const [modalConfirm, setModalConfirm] = useState(null);

  const onUpdateToggle = useCallback((appt) => {
    const addOnService = appointments.find((item) => +item.id === +appt.id && item.addOnServiceId !== undefined);
    setModalUpdate({ ...appt, addOnService: addOnService ? addOnService.addOnServiceId : null });
  }, [appointments]);

  const onUpdateConfirm = useCallback(() => {
    updateAppointments({ setup, teamSessions });
    setModalUpdate(null);
    setModalConfirm(true);
    setTimeout(() => setModalConfirm(null), 3000);
  }, [setup, teamSessions, updateAppointments]);

  const onUpdateCancel = useCallback(() => {
    setModalUpdate(null);
  }, []);

  return {
    modalUpdate,
    modalConfirm,
    onUpdateCancel,
    onUpdateToggle,
    onUpdateConfirm,
  };
}
