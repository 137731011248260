import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import optionHandler from 'store/effects/admin/handlers';
import { extendStyles } from 'helpers';
import { useOutsideClick } from 'hooks';

import { InputGroup } from '..';
import { Search } from './components';

import styles from './styles.scss';

extendStyles(styles);

const Select = (props) => {
  const {
    name,
    label,
    error,
    style,
    option,
    options,
    disabled,
    selected,
    onChange,
    autoFocus,
    placeholder,
  } = props;

  const [search, setSearch] = useState(null);
  const [focused, setFocused] = useState(false);
  const [modalState, setModalState] = useState({ open: false });
  const [activeOption, setActiveOption] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const btnRef = useRef();
  const modalRef = useRef();

  useOutsideClick([btnRef, modalRef], () => {
    setSearch(null);
    setFocused(false);
    setIsCollapsed(true);
  });

  useEffect(() => {
    if (!autoFocus) return;
    setFocused(true);
  }, [autoFocus]);

  const onToggle = () => {
    if (disabled) return;
    setFocused(isCollapsed);
    setIsCollapsed((c) => !c);
  };

  const handleFocus = (val) => setFocused(val);

  const handleChange = (value) => {
    if (disabled) return;
    setSearch(value);
  };

  const handleChangeInput = (e) => {
    e.persist();

    const fieldValue = e.target.value;

    setModalState((fd) => ({
      ...fd,
      field: {
        value: fieldValue,
        error: !fieldValue.length,
      },
    }));
  };

  const onSelectOption = (value) => {
    onChange(name, value);
    setSearch(null);
    setFocused(false);
    setIsCollapsed(true);
  };

  const handleReset = () => {
    onChange(name, null);
    setSearch(null);
    setIsCollapsed(true);
  };

  const filterOptions = (opts) => {
    const sortedOptions = opts.sort((a, b) => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;
      return 0;
    });
    if (!search) return sortedOptions;
    return sortedOptions.filter((o) => o.value.toLowerCase().includes(search.toLowerCase()));
  };

  const handleKeyPress = (e) => {
    const { key } = e;
    if (key === 'ArrowDown') {
      if (isCollapsed) setIsCollapsed(false);
      if (!activeOption) setActiveOption(options[0].id);
      else {
        const filteredOptions = filterOptions(options);
        const index = filteredOptions.findIndex((o) => o.id === activeOption);
        const next = index < filteredOptions.length - 1 ? index + 1 : 0;
        setActiveOption(filteredOptions[next].id);
      }
    } else if (key === 'ArrowUp') {
      if (isCollapsed) setIsCollapsed(false);
      if (!activeOption) return;
      const filteredOptions = filterOptions(options);
      const index = filteredOptions.findIndex((o) => o.id === activeOption);
      const next = index > 0 ? index - 1 : filteredOptions.length - 1;
      setActiveOption(filteredOptions[next].id);
    } else if (key === 'Enter') {
      if (!activeOption) return;
      onSelectOption(activeOption);
    }
  };

  const renderOption = (item) => {
    const { id, value } = item;

    return (
      <div
        key={ id }
        className={ styles.get('option', activeOption === id && 'active') }
        onClick={ () => onSelectOption(id) }
        onMouseEnter={ () => setActiveOption(id) }
      >
        { value }
      </div>
    );
  };

  const onAddNewToggle = () => setModalState({
    open: true,
    field: { value: null, error: false },
  });

  const onSave = () => {
    if (modalState.field.value && modalState.field.value.length) {
      optionHandler.addOption({
        category: option.category,
        tableName: option.tableName,
        option: modalState.field.value,
      }, () => {
        setModalState({ open: false });
        setSearch(modalState.field.value);
      });
    }
  };

  const onCancel = () => setModalState({ open: false });

  const toTitleCase = () => {
    const str = label || name;
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  };

  return (
    <div className={ styles.select } style={ style }>
      { label && <div className={ styles.get('label') }>{ label }</div> }
      <div
        ref={ btnRef }
        style={ style }
        className={ styles.get('filter', focused && 'focused', error && 'error') }
      >
        <Search
          name="search"
          value={ search || selected }
          style={ style }
          focused={ focused }
          placeholder={ placeholder }
          onReset={ handleReset }
          onFocus={ handleFocus }
          onChange={ handleChange }
          onKeyPress={ handleKeyPress }
        />
        <div className={ styles.get('icon', focused && 'focused') } onClick={ onToggle }>
          <div className={ styles.arrow } />
        </div>
      </div>
      { (!isCollapsed || search) && (
        <div
          ref={ modalRef }
          className={ styles.get('modal', label && 'with-label') }
          style={ {
            ...style,
            minWidth: style.width || 250,
            width: 'auto',
          } }
        >
          { option && !modalState.open && !search && (
          <div className={ styles.button } onClick={ onAddNewToggle }>
            <div className={ styles.new } />
            <div className={ styles.text }>Add New</div>
          </div>
          )}
          { !modalState.open
            ? (
              <div className={ styles.scrollable }>
                { filterOptions(options).map(renderOption) }
              </div>
            )
            : (
              <div className={ styles.form }>
                <div className={ styles.title }>{ `New ${toTitleCase(label)}` }</div>
                <InputGroup
                  name="field"
                  label={ label }
                  value={ modalState.field.value }
                  error={ modalState.field.error }
                  style={ { width: '100%' } }
                  onChange={ handleChangeInput }
                />
                <div className={ styles.buttonGroup }>
                  <div className={ styles.get('btn', 'accept') } onClick={ onSave }>Save</div>
                  <div className={ styles.get('btn', 'cancel') } onClick={ onCancel }>Cancel</div>
                </div>
              </div>
            )}
        </div>
      )}
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.bool,
  style: PropTypes.shape({ width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
  option: PropTypes.shape({ category: PropTypes.string, tableName: PropTypes.string }),
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })).isRequired,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Select.defaultProps = {
  error: false,
  label: null,
  style: {},
  option: null,
  selected: null,
  disabled: false,
  autoFocus: false,
  placeholder: null,
};

export default Select;
