import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

import teamHandler from 'store/effects/team/handlers';
import adminHandler from 'store/effects/admin/handlers';
import eventHandler from 'store/effects/items/events/handlers';
import guestHandler from 'store/effects/guest/handlers';
import serviceHandler from 'store/effects/items/services/handlers';
import sessionHandler from 'store/effects/session/handlers';
import visitorHandler from 'store/effects/visitor/handlers';
import teamSessionHandler from 'store/effects/teamSession/handlers';
import guestSessionHandler from 'store/effects/guestSession/handlers';

import { useWebsocket } from 'hooks';

import { Audit, NotFound } from '..';

const App = () => {
  useEffect(() => {
    teamHandler.getAllTeams();
    adminHandler.getAllAdminData();
    eventHandler.getAllEvents();
    guestHandler.getAllGuests();
    serviceHandler.getAllServices();
    sessionHandler.handleGetAllSessions();
    visitorHandler.getAllVisitors();
    teamSessionHandler.handleGetAllTeamSessions();
    guestSessionHandler.handleGetAllGuestSessions();
  }, []);

  useWebsocket();

  return (
    <Switch>
      <Route path="/:hash" component={ Audit } />
      <Route path="*" component={ NotFound } />
    </Switch>
  );
};

export default App;
