/* eslint-disable max-len */
import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Snackbar from '@material-ui/core/Snackbar';

import { Message } from 'components';
import { useData, useLegend } from 'hooks';
import { extendStyles, formatCurrency } from 'helpers';

import { Modal } from './components';
import { ColorLegend, ModalDelete, ModalNote } from '../../components';
import { useAppointments, useModalCreate, useModalUpdate, useModalDelete, useModalNote } from '../../hooks';

import styles from './styles.scss';

extendStyles(styles);

const DesktopView = ({ setup }) => {
  const [hover, setHover] = useState(null);
  const [action, setAction] = useState({ error: false, done: false });
  const [showLegend, setLegend] = useState(false);

  const { legend } = useLegend();
  const { teams, teamSessions } = useData();
  const { appointments, appointmentDates, updateAppointments } = useAppointments({ setup, teamSessions });
  const {
    modalNote,
    onNoteCancel,
    onNoteToggle,
    onNoteConfirm } = useModalNote({ setup, teamSessions, updateAppointments, setAction });
  const {
    modalCreate,
    modalConfirm: modalCreateConfirm,
    onCreateCancel,
    onCreateToggle,
    onCreateConfirm,
  } = useModalCreate({ setup, teamSessions, updateAppointments });
  const {
    modalUpdate,
    modalConfirm: modalUpdateConfirm,
    onUpdateCancel,
    onUpdateToggle,
    onUpdateConfirm } = useModalUpdate({ setup, teamSessions, appointments, updateAppointments });
  const {
    modalDelete,
    modalConfirm: modalDeleteConfirm,
    onDeleteCancel,
    onDeleteToggle,
    onDeleteConfirm } = useModalDelete({ setup, teamSessions, appointments, updateAppointments, setAction });

  const teamName = useMemo(() => {
    if (!teams || !teams[setup.teamId]) return null;
    const { firstName, lastName } = teams[setup.teamId];
    return `${firstName} ${lastName}`;
  }, [teams, setup]);

  const days = useMemo(() => moment(setup.endDate).diff(moment(setup.startDate), 'd') + 1, [setup]);

  const dates = useMemo(() => `${moment(setup.startDate).format('ddd, MMM DD, YYYY')} - ${moment(setup.endDate).format('ddd, MMM DD, YYYY')}`, [setup]);

  const openLegend = useCallback(() => { setLegend(true); }, []);
  const closeLegend = useCallback(() => { setLegend(false); }, []);

  const getAppointmentName = useCallback((appt) => {
    if (!appt.addOnServiceId) return appt.name;
    return (
      <>
        <b>AddOn:</b>
        {appt.name}
      </>
    );
  }, []);

  const isAppointmentHasRequest = useCallback((appt) => {
    if (appt.requestedOperation !== undefined) return true;
    const requestedAppointment = (appointments || []).find((item) => +item.requestedId === +appt.id);
    return requestedAppointment === undefined;
  }, [appointments]);

  const rowStyles = useMemo(() => (legend || [])
    .reduce((res, { color, label }) => {
      if (label.indexOf('add') !== -1) return { ...res, '--create': color };
      if (label.indexOf('edit') !== -1) return { ...res, '--update': color };
      if (label.indexOf('deleted') !== -1) return { ...res, '--delete': color };
      if (label.indexOf('unchanged') !== -1) return { ...res, '--unchanged': color };
      return res;
    }, {}), [legend]);

  const renderAppointment = (appt) => {
    let key = appt.addOnServiceId ? `${appt.id}-addOn` : appt.id;
    if (appt.requestedOperation !== undefined) key += '-request';

    return (
      <tr
        key={ key }
        style={ rowStyles }
        className={ styles.get('row', appt.requestedOperation || '') }
        onMouseEnter={ () => setHover(appt.id) }
        onMouseLeave={ () => setHover(null) }
      >
        <td className={ styles.get('cell', 'time') }>{ moment(appt.dateTime).format('HH:mm A') }</td>
        <td className={ styles.get('cell', 'cost') }>{ formatCurrency(appt.price) }</td>
        <td className={ styles.get('cell', 'service') }>{ getAppointmentName(appt) }</td>
        <td className={ styles.get('cell', 'client') }>{ appt.guest }</td>
        <td className={ styles.get('cell', 'actions') }>
          { (hover === appt.id || appt.notes) && (
            <div className={ styles.get('button', appt.notes ? 'updateNote' : 'addNote') } onClick={ () => onNoteToggle(appt) }>
              <span className={ styles.tooltip }>{ `${appt.notes ? 'Update a Note' : 'Add a Note'}` }</span>
            </div>
          )}
          { hover === appt.id && appt.requestedOperation !== 'delete' && (
            <div className={ styles.get('button', 'update') } onClick={ () => onUpdateToggle(appt) }>
              <span className={ styles.tooltip }>Edit Appointment</span>
            </div>
          )}
          { hover === appt.id && (
            <div className={ styles.get('button', 'delete') } onClick={ () => onDeleteToggle(appt) }>
              <span className={ styles.tooltip }>{ appt.requestedOperation === 'delete' ? 'Restore Appointment' : 'Delete Appointment'}</span>
            </div>
          )}
        </td>
      </tr>
    );
  };

  const renderDayAppointments = (date) => (
    <div key={ date } className={ styles.appointments }>
      <div className={ styles.date }>{ moment(date).format('dddd, MMM DD, YYYY') }</div>
      <table className={ styles.table }>
        <tbody>
          { (appointments || [])
            .filter((appt) => isAppointmentHasRequest(appt)
              && moment(appt.dateTime).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'))
            .sort((a, b) => {
              if (moment(a.dateTime).isBefore(moment(b.dateTime))) return -1;
              if (moment(a.dateTime).isAfter(moment(b.dateTime))) return 1;
              return 0;
            })
            .map(renderAppointment)}
        </tbody>
      </table>
    </div>
  );

  const handleCloseMessage = () => setAction({ error: false, done: false });

  if (!teamName || !days) return null;

  return (
    <div className={ styles.container }>
      <div className={ styles.heading }>
        <div className={ styles.group }>
          Appointments Audit:
          <div className={ styles.strong }>{ teamName }</div>
        </div>
        <div className={ styles.legend } onClick={ openLegend } />
      </div>
      <div className={ styles.period }>
        Period:
        <div className={ styles.strong }>{ `${days} Days` }</div>
        <div className={ styles.separator } />
        <div className={ styles.dates }>{ dates }</div>
      </div>
      { Object
        .values(appointmentDates)
        .sort((a, b) => {
          if (moment(a.dateTime).isBefore(moment(b.dateTime))) return -1;
          if (moment(b.dateTime).isBefore(moment(a.dateTime))) return 1;
          return 0;
        })
        .map(renderDayAppointments)}
      <div className={ styles.add } onClick={ onCreateToggle }>Add Missing Appointment/Event</div>
      <div className={ styles.blank } />
      <div className={ styles.note }><strong>NOTE:</strong> After check out been completed, you will not be reimbursed for any changes or missed appointments. We tried, it ruins the realationship with the guest, when they find out they owe, yet more. However if you find the charge for the service that was not performed, we&apos;ll reimburse the guest.</div>
      <div className={ styles.note }>Review of submited notes by Fresh Start: if they&apos;ve been prior approved by the Guest, w&apos;ll approve the update. If we have questions, we&apos;ll reach out to you. Please be very spefic when leaving the notes.</div>
      <div className={ styles.note }>I&apos;ve reviewed all appointments and I agree to the terms above.</div>
      <div className={ styles.note }>Please do the audits ON TIME :)</div>
      { showLegend && <ColorLegend onClose={ closeLegend } /> }
      { modalCreate && (
        <Modal
          setup={ setup }
          onSave={ onCreateConfirm }
          onCancel={ onCreateCancel }
        />
      )}
      { modalUpdate && (
        <Modal
          setup={ setup }
          onSave={ onUpdateConfirm }
          onCancel={ onUpdateCancel }
          appointment={ modalUpdate }
        />
      )}
      { modalNote && (
        <ModalNote
          notes={ modalNote.notes }
          onCancel={ onNoteCancel }
          onConfirm={ onNoteConfirm }
        />
      )}
      { modalDelete && (
        <ModalDelete
          type="warning"
          value={ modalDelete.name }
          query={ modalDelete.requestedOperation === 'delete' ? 'Restore' : 'Delete' }
          onCancel={ onDeleteCancel }
          onConfirm={ onDeleteConfirm }
          labelPrimary={ modalDelete.requestedOperation === 'delete' ? 'Are you sure you want to restore' : 'Are you sure you want to request the deletion of' }
          labelSecondary="Appointment: "
          isRequireAction={ false }
        />
      )}
      <Snackbar
        anchorOrigin={ { vertical: 'bottom', horizontal: 'left' } }
        open={ action.done }
        autoHideDuration={ 2000 }
        onClose={ handleCloseMessage }
      >
        <Message message="Success" variant="success" onClose={ handleCloseMessage } />
      </Snackbar>
      <Snackbar
        anchorOrigin={ { vertical: 'bottom', horizontal: 'left' } }
        open={ action.error }
        autoHideDuration={ 2000 }
        onClose={ handleCloseMessage }
      >
        <Message message="Error" variant="error" onClose={ handleCloseMessage } />
      </Snackbar>
      {(modalCreateConfirm || modalUpdateConfirm || modalDeleteConfirm) && (
        <Snackbar
          anchorOrigin={ { vertical: 'bottom', horizontal: 'left' } }
          open={ modalCreateConfirm || modalUpdateConfirm || modalDeleteConfirm }
          autoHideDuration={ 2000 }
        >
          <Message message="Submitted for audit" variant="success" />
        </Snackbar>
      )}
    </div>
  );
};

DesktopView.propTypes = {
  setup: PropTypes.shape({
    teamId: PropTypes.number,
    endDate: PropTypes.string,
    startDate: PropTypes.string,
  }).isRequired,
};

export default DesktopView;
