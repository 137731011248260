export const prepareSelect = ({ data }) => {
  return Object
    .values(data || {})
    .filter((item) => !item.archived)
    .map((item) => ({ id: +item.id, value: item.billingDescription }))
    .sort((a, b) => {
      if (a.value < b.value) return -1;
      if (b.value < a.value) return 1;
      return 0;
    });
};
