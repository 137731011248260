import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';

import styles from './styles.scss';

extendStyles(styles);

const ModalNote = ({ notes, onConfirm, onCancel, isMobile }) => {
  const [text, setText] = useState('');

  useEffect(() => {
    if (!notes) return;
    setText(notes);
  }, [notes]);

  const onChange = useCallback((e) => {
    e.persist();
    setText(e.target.value);
  }, []);

  const onSubmit = useCallback(() => {
    onConfirm(text);
  }, [text]);

  return (
    <div className={ styles.overlay }>
      <div className={ styles.modal }>
        <div className={ styles.inner }>
          <div className={ styles.close } onClick={ onCancel } />
          <div className={ styles.heading }>{ `${notes ? 'Update Note' : 'Add Note'}` }</div>
          <div className={ styles.separator } />
          <div className={ styles.row }>
            <input type="text" className={ styles.input } value={ text } onChange={ onChange } />
          </div>
          <div className={ styles.get('separator', 'green') } />
          <div className={ styles.get('buttonGroup', isMobile && 'mobile') }>
            <div className={ styles.get('button', 'yes') } onClick={ onSubmit }>Yes</div>
            <div className={ styles.get('button', 'no') } onClick={ onCancel }>No</div>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalNote.propTypes = {
  note: PropTypes.string,
  isMobile: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

ModalNote.defaultProps = {
  note: null,
  isMobile: false,
};

export default ModalNote;
