import moment from 'moment';
import { getOptionTextById } from 'helpers';

export const CLIENTS = {
  GUEST: 'Guest',
  TEAM: 'Team',
  VISITOR: 'Visitor',
};

export const prepareSelect = ({ guests, teams, visitors, activeTab, guestSessions, options, setup }) => {
  const data = {
    [CLIENTS.GUEST]: guests,
    [CLIENTS.TEAM]: teams,
    [CLIENTS.VISITOR]: visitors,
  }[activeTab];

  if (activeTab === CLIENTS.GUEST) {
    return Object
      .values(guestSessions || {})
      .filter((gs) => {
        const { programStartDate, programFinishDate } = gs.details;
        const { arrivalDate, departureDate } = gs.travel;
        const customStartDate = programStartDate || arrivalDate;
        const customEndDate = programFinishDate || departureDate;

        const isGsTouchStart = moment(customStartDate).isSameOrBefore(moment(setup.startDate))
          && moment(customEndDate).isSameOrAfter(moment(setup.startDate));
        const isGsTouchEnd = moment(customStartDate).isSameOrBefore(moment(setup.endDate))
          && moment(customEndDate).isSameOrAfter(moment(setup.endDate));
        const isGsInsideSetup = moment(customStartDate).isSameOrAfter(moment(setup.startDate))
          && moment(customEndDate).isSameOrBefore(moment(setup.endDate));

        const isWithinSetupDates = isGsTouchStart || isGsTouchEnd || isGsInsideSetup;
        const isSameSession = +gs.sessionId === +setup.sessionId;
        const isArchived = gs.archived;
        return isWithinSetupDates && isSameSession && !isArchived;
      })
      .map((gs) => {
        const cycle = getOptionTextById({ data: options, field: 'programCycles', id: gs.details.cycle });
        return {
          id: +gs.id,
          value: `${guests[gs.guestId].firstName} ${guests[gs.guestId].lastName}, ${cycle}`,
        };
      })
      .sort((a, b) => {
        if (a.value < b.value) return -1;
        if (b.value < a.value) return 1;
        return 0;
      });
  }

  return Object
    .values(data || {})
    .filter((item) => !item.archived)
    .map((item) => ({ id: +item.id, value: `${item.firstName} ${item.lastName}` }))
    .sort((a, b) => {
      if (a.value < b.value) return -1;
      if (b.value < a.value) return 1;
      return 0;
    });
};
