import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useData } from 'hooks';
import { formatCurrency } from 'helpers';
import { Select, InputGroup, Checkbox } from 'components';

import { prepareSelect } from './helpers';

import styles from './styles.scss';

const Service = ({ formData, setup, error, disabled, handleChange, handleSelect }) => {
  const { services } = useData();

  const getServiceName = useCallback((id) => {
    if (!services || !services[id]) return null;
    return services[id].billingDescription;
  }, [services]);

  const getServiceDuration = useCallback((id) => {
    if (!services || !services[id]) return null;
    return services[id].serviceDuration;
  }, [services]);

  const getServiceCleanUp = useCallback((id) => {
    if (!services || !services[id]) return null;
    return services[id].serviceCleanUpTime;
  }, [services]);

  const servicePrice = useMemo(() => {
    if (!services || !formData.service || !services[formData.service]) return null;
    return formatCurrency(services[formData.service].purchasePrice);
  }, [services, formData.service]);

  return (
    <div className={ styles.card }>
      <div className={ styles.row }>
        <Select
          name="service"
          label="SERVICE *"
          error={ error }
          style={ { width: '100%' } }
          options={ prepareSelect({ data: services, teamId: setup.teamId }) }
          selected={ getServiceName(formData.service) }
          disabled={ disabled }
          onChange={ handleSelect }
          placeholder="Select Service"
        />
      </div>
      <div className={ styles.price }>
        { `Rate: ${servicePrice || '$PurchasePrice'}` }
      </div>
      <div className={ styles.row }>
        <InputGroup
          label="DURATION"
          name="duration"
          value={ getServiceDuration(formData.service) }
          style={ { width: 100, marginRight: 20 } }
          onChange={ () => null }
          isDisabled
        />
        <InputGroup
          label="CLEAN UP"
          name="cleanUp"
          value={ getServiceCleanUp(formData.service) }
          style={ { width: 100 } }
          onChange={ () => null }
          isDisabled
        />
      </div>
      <div className={ styles.row }>
        <Checkbox
          id="doneByFsEmployee"
          name="doneByFsEmployee"
          label="DONE BY FS EMPLOYEE"
          style={ { marginBottom: 5 } }
          onChange={ handleChange }
          checked={ formData.doneByFsEmployee }
        />
      </div>
    </div>
  );
};

Service.propTypes = {
  error: PropTypes.bool.isRequired,
  setup: PropTypes.shape({ teamId: PropTypes.number }).isRequired,
  disabled: PropTypes.bool.isRequired,
  formData: PropTypes.shape({
    service: PropTypes.number,
    doneByFsEmployee: PropTypes.bool,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default Service;
