/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';

import styles from './styles.scss';

extendStyles(styles);

const Modal = ({ type, labelPrimary, value, onConfirm, onCancel }) => (
  <div className={ styles.overlay }>
    <div className={ styles.modal }>
      <div className={ styles.inner }>
        <div className={ styles.close } onClick={ onCancel } />
        <div className={ styles.heading }>Delete Request Confirmation</div>
        <div className={ styles.get('icon', type) } />
        <div className={ styles.separator } />
        <div className={ styles.get('label', 'primary') }>{ labelPrimary }</div>
        <div className={ styles.labelGroup }>
          <div className={ styles.get('label', 'strong') }>{ `Appointment: ${value}` }</div>
        </div>
        <div className={ styles.get('separator', 'green') } />
        <div className={ styles.labelInline }>
          <div className={ styles.get('label', 'left') }>
            <strong>Note:</strong>
            Appointment will be deleted only when approved by Fresh Start. This request is just an indication that you did not perform this appointment.
          </div>
        </div>
        <div className={ styles.buttonGroup }>
          <div className={ styles.get('button', 'yes') } onClick={ onConfirm }>Yes</div>
          <div className={ styles.get('button', 'no') } onClick={ onCancel }>No</div>
        </div>
      </div>
    </div>
  </div>
);

Modal.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  labelPrimary: PropTypes.string.isRequired,
};

export default Modal;
